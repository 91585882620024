import React, { FC, useState } from 'react';
import { Link } from '@reach/router';
import useWindowSize from '../hooks/useWindowSize';
import { graphql, useStaticQuery } from 'gatsby';
import { IFluidObject } from 'gatsby-background-image';
import { Routes } from '../constants/Routes';
import { Label, Input } from './FormElements';

const KURS_QUERY = graphql`
  {
    allContentfulKurs {
      nodes {
        titel
        kategorie
      }
    }
  }
`;

const days = ['montag', 'dienstag', 'mittwoch', 'donnerstag', 'freitag', 'samstag'] as const;

type Day = 'montag' | 'dienstag' | 'mittwoch' | 'donnerstag' | 'freitag' | 'samstag';

export type StundenplanItem = {
  name: string;
  day: Day;
  displayCourseName: string;
  startTime: Date;
  endTime: Date;
  teacher?: string;
  teacherFullName?: string;
  course?: string;
  kategorie?: string;
};

type Props = {
  items: StundenplanItem[];
};

const capitalize = (input: string) => input[0].toUpperCase() + input.substring(1, input.length);

const isHighlighted = (item: StundenplanItem, query: string) =>
  query.length > 0 &&
  (item.displayCourseName.toLowerCase().includes(query.toLowerCase()) ||
    (item.teacher && item.teacher.toLowerCase().includes(query.toLowerCase())));

type Kurs = {
  titel: string;
  kategorie: 'kinder' | 'erwachsene';
};

const Stundenplan: FC<Props> = ({ items }) => {
  const kurse: Kurs[] = useStaticQuery(KURS_QUERY).allContentfulKurs.nodes;

  const [query, setQuery] = useState('');
  const [activeDay, setActiveDay] = useState<Day>('montag');
  const { width } = useWindowSize();
  const formatTimeToHHMM = (date: Date) =>
    `${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;

  const generateCourseLink = (item: StundenplanItem) => {
    const lowerCaseName = item.name.toLowerCase();
    if (lowerCaseName.includes('förderklasse') || lowerCaseName.includes('open class')) {
      return Routes.FOERDERKLASSE;
    }

    if (item.course) {
      const kurs = kurse.find(k => k.titel === item.course);
      if (kurs) {
        return `${item.kategorie.toLowerCase()}/${kurs.titel.replace(/(\(.*?)\)/, '').trim()}`;
      }
    }

    // : kurse.find(k => k.titel === item.course)
    // ? `${kurse.find(k => k.titel === item.course).kategorie.toLowerCase()}/${kurse
    //     .find(k => k.titel === item.course)
    //     .titel.replace(/(\(.*?)\)/, '')
    //     .trim()}`
    // : Routes.ERWACHSENE
    return Routes.ERWACHSENE;
  };


  return (
    <div>
      <div className="lg:flex lg:flex-col items-end w-full">
        <div className="flex justify-between w-full">
          <div className="p-4 lg:p-0">
            <div className="bg-p5 p-2">Kurse für Erwachsene</div>
            <div className="bg-p1 p-2">Kurse für Kinder / Jugendliche</div>
          </div>
          <div className="mx-4 lg:mx-0 lg:w-64">
            <Label htmlFor="search">
              Suche
              <Input
                placeholder="ballet"
                value={query}
                onChange={e => setQuery(e.target.value)}
                name="search"
                id="search"
                type="text"
              />
            </Label>
          </div>
        </div>
        <div className="lg:flex w-full">
          {width &&
            days.map(day => (
              <div key={day} className="px-4 pt-2 lg:w-1/6 lg:border lg:border-gray-400">
                <p
                  onClick={() => setActiveDay(day)}
                  className="font-bold mt-4 bg-primary w-full text-white text-center py-2"
                >{`${capitalize(day)}`}</p>
                {items
                  .filter(item => item.day === day)
                  .filter(item => (width < 768 ? item.day === activeDay : true))
                  .slice()
                  .sort((a, b) => a.startTime.toString().localeCompare(b.startTime.toString()))
                  .map(item => (
                    <div
                      key={item.name + Math.random()}
                      className={`text-gray-900 mt-4 px-4 py-4 flex flex-col items-center text-md text-center ${
                        isHighlighted(item, query)
                          ? item.kategorie === 'Kinder'
                            ? 'text-p6 underline'
                            : 'text-p1 underline'
                          : ''
                      } ${item.kategorie && item.kategorie === 'Kinder' ? 'bg-p1' : 'bg-p5'} `}
                    >
                      <p>
                        {formatTimeToHHMM(item.startTime)} - {formatTimeToHHMM(item.endTime)}
                      </p>
                      <Link className="font-bold" to={generateCourseLink(item)}>
                        {item.displayCourseName}
                      </Link>
                      {item.teacherFullName && (
                        <Link className="" to={'/' + item.teacherFullName}>
                          {item.teacher}
                        </Link>
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Stundenplan;
