import React, { FC } from "react";

export const Input: FC<React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>> = props => (
  <input
    className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
    {...props}
  />
);

export const TextArea: FC<React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>> = props => (
  <textarea
    rows={10}
    className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
    {...props}
  />
);

export const Label: FC<React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>> = ({
  children,
  ...props
}) => (
  <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-4" {...props}>
    {children}
  </label>
);